<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="container">
        loading...
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage
  },
  created() {
    this.query = this.$route.query
    this.signin()
  },
  data () {
    return {
      query: null
    }
  },
  methods: {
    async signin() {
      let token = this.query.token || null
      let expires = (new Date).getTime() + ( 30000 * 1000) - (60 * 1000)
      await this.$store.commit('auth/setToken', {token: token, ttl: expires})
      await this.$store.dispatch('auth/refreshToken').then(res => {
        if(! res.error)
          this.$router.push({name: 'ResetPassword'})
      })
    }
  }
})
</script>

<style>

</style>
